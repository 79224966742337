import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f629ef2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "empty-state"
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "ion-hide-md-down" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "ion-text-wrap" }
const _hoisted_7 = {
  class: "product-metadata",
  slot: "end"
}
const _hoisted_8 = {
  key: 3,
  class: "atp-info"
}
const _hoisted_9 = {
  key: 0,
  class: "kit-components ion-margin"
}
const _hoisted_10 = { class: "overline" }
const _hoisted_11 = {
  key: 2,
  class: "empty-state"
}
const _hoisted_12 = {
  key: 3,
  class: "empty-state"
}
const _hoisted_13 = { class: "info" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "overline" }
const _hoisted_16 = {
  slot: "end",
  class: "ion-text-end"
}
const _hoisted_17 = {
  key: 1,
  class: "empty-state"
}
const _hoisted_18 = { class: "other-shipments" }
const _hoisted_19 = { class: "ion-padding" }
const _hoisted_20 = { class: "overline" }
const _hoisted_21 = { class: "ion-text-wrap" }
const _hoisted_22 = { slot: "end" }
const _hoisted_23 = {
  key: 1,
  class: "atp-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_card_subtitle = _resolveComponent("ion-card-subtitle")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_back_button, {
                "default-href": "/",
                slot: "start"
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("Order details")), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  (_ctx.orderType === 'packed' && _ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendReadyForPickupEmail(_ctx.order)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.mailOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_button, {
                    disabled: !_ctx.order?.orderId,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openOrderItemRejHistoryModal()))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "icon-only",
                        icon: _ctx.timeOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"]),
                  (_ctx.orderType === 'open')
                    ? (_openBlock(), _createBlock(_component_ion_button, {
                        key: 1,
                        disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped || !_ctx.order.part?.items?.length,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.printPicklist(_ctx.order, _ctx.order.part)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            icon: _ctx.printOutline
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 8, ["disabled"]))
                    : (_ctx.orderType === 'packed' && _ctx.getBopisProductStoreSettings('PRINT_PACKING_SLIPS'))
                      ? (_openBlock(), _createBlock(_component_ion_button, {
                          key: 2,
                          class: _normalizeClass(_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId !== 'STOREPICKUP' ? 'ion-hide-md-up' : ''),
                          disabled: !_ctx.order?.orderId || !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped || !_ctx.order.part?.items?.length,
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.printPackingSlip(_ctx.order) : _ctx.printShippingLabelAndPackingSlip(_ctx.order)))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "icon-only",
                              icon: _ctx.printOutline
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        }, 8, ["class", "disabled"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (!_ctx.order?.orderId)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("p", null, _toDisplayString(_ctx.translate("Order not found")), 1)
              ]))
            : (_openBlock(), _createElementBlock("main", _hoisted_2, [
                _createElementVNode("aside", _hoisted_3, [
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.translate("Timeline")), 1),
                      _createVNode(_component_ion_badge, {
                        slot: "end",
                        color: _ctx.getColorByDesc(_ctx.orderStatus)
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate(_ctx.orderStatus)), 1)
                        ]),
                        _: 1
                      }, 8, ["color"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, { class: "ion-margin-start desktop-only" }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderTimeline, (event) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          key: event.id
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              icon: event.icon,
                              slot: "start"
                            }, null, 8, ["icon"]),
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                (event.timeDiff)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(event.timeDiff), 1))
                                  : _createCommentVNode("", true),
                                _createTextVNode(" " + _toDisplayString(_ctx.translate(event.label)) + " ", 1),
                                (event.metaData)
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(event.metaData), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            (event.value && event.valueType === 'date-time-millis')
                              ? (_openBlock(), _createBlock(_component_ion_note, {
                                  key: 0,
                                  slot: "end"
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.formatDateTime(event.value)), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("section", null, [
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        slot: "start",
                        icon: _ctx.ticketOutline
                      }, null, 8, ["icon"]),
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createElementVNode("h1", null, _toDisplayString(_ctx.order.orderName), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.order.orderId), 1)
                        ]),
                        _: 1
                      }),
                      (_ctx.order.pickers && (_ctx.orderType === 'open' || _ctx.orderType === 'packed') && _ctx.getBopisProductStoreSettings('ENABLE_TRACKING'))
                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                            key: 0,
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE),
                            outline: "",
                            slot: "end",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.editPicker(_ctx.order)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.personOutline }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.order.pickers), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["disabled"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (_ctx.order.readyToHandover || _ctx.order.readyToShip)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        color: "light",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.checkmarkCircleOutline,
                            color: "success",
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.readyToHandover ? _ctx.translate("Order is now ready to handover.") : _ctx.translate("Order is now ready to be shipped.")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.order.handovered || _ctx.order.shipped)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 1,
                        color: "light",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            icon: _ctx.checkmarkCircleOutline,
                            color: "success",
                            slot: "start"
                          }, null, 8, ["icon"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.order.handovered ? _ctx.translate("Order is successfully handed over to customer.") : _ctx.translate("Order is successfully shipped.")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_card, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.part?.items, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "order-item"
                        }, [
                          _createVNode(_component_ion_item, {
                            class: "product-info",
                            lines: "none"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_DxpShopifyImg, {
                                    src: _ctx.getProduct(item.productId).mainImageUrl,
                                    size: "small"
                                  }, null, 8, ["src"])
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                                  _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                                  (_ctx.isKit(item))
                                    ? (_openBlock(), _createBlock(_component_ion_badge, {
                                        key: 0,
                                        color: "dark"
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, 1024),
                              _createElementVNode("div", _hoisted_7, [
                                (_ctx.orderType === 'open' && !(_ctx.order.readyToHandover || _ctx.order.readyToShip))
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      (item.rejectReason)
                                        ? (_openBlock(), _createBlock(_component_ion_chip, {
                                            key: 0,
                                            outline: "",
                                            color: "danger",
                                            onClick: _withModifiers(($event: any) => (_ctx.openRejectReasonPopover($event, item, _ctx.order)), ["stop"])
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_icon, {
                                                icon: _ctx.closeCircleOutline,
                                                onClick: _withModifiers(($event: any) => (_ctx.removeRejectionReason($event, item, _ctx.order)), ["stop"])
                                              }, null, 8, ["icon", "onClick"]),
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(item.rejectReason)), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                            ]),
                                            _: 2
                                          }, 1032, ["onClick"]))
                                        : (_ctx.isEntierOrderRejectionEnabled(_ctx.order))
                                          ? (_openBlock(), _createBlock(_component_ion_chip, {
                                              key: 1,
                                              outline: "",
                                              color: "danger",
                                              onClick: _withModifiers(($event: any) => (_ctx.openRejectReasonPopover($event, item, _ctx.order)), ["stop"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_label, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) ? _ctx.getRejectionReasonDescription(_ctx.rejectEntireOrderReasonId) : _ctx.translate("Reject to avoid order split (no variance)")), 1)
                                                  ]),
                                                  _: 1
                                                }),
                                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 2,
                                              slot: "end",
                                              color: "danger",
                                              fill: "clear",
                                              size: "small",
                                              onClick: _withModifiers(($event: any) => (_ctx.openRejectReasonPopover($event, item, _ctx.order)), ["stop"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                  slot: "icon-only",
                                                  icon: _ctx.trashOutline
                                                }, null, 8, ["icon"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                    ], 64))
                                  : (_ctx.orderType === 'packed' && !(_ctx.order.handovered || _ctx.order.shipped))
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                        (item.cancelReason)
                                          ? (_openBlock(), _createBlock(_component_ion_chip, {
                                              key: 0,
                                              outline: "",
                                              color: "danger",
                                              onClick: _withModifiers(($event: any) => (_ctx.openCancelReasonPopover($event, item, _ctx.order)), ["stop"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                  icon: _ctx.closeCircleOutline,
                                                  onClick: _withModifiers(($event: any) => (_ctx.removeCancellationReason($event, item, _ctx.order)), ["stop"])
                                                }, null, 8, ["icon", "onClick"]),
                                                _createVNode(_component_ion_label, null, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.getCancelReasonDescription(item.cancelReason)), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_ion_icon, { icon: _ctx.caretDownOutline }, null, 8, ["icon"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                          : (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 1,
                                              slot: "end",
                                              color: "danger",
                                              fill: "clear",
                                              size: "small",
                                              onClick: _withModifiers(($event: any) => (_ctx.openCancelReasonPopover($event, item, _ctx.order)), ["stop"])
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate("Cancel")), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]))
                                      ], 64))
                                    : _createCommentVNode("", true),
                                (item.isFetchingStock)
                                  ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                      key: 2,
                                      color: "medium",
                                      name: "crescent"
                                    }))
                                  : (_ctx.getProductStock(item.productId).quantityOnHandTotal >= 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                        _createVNode(_component_ion_note, { slot: "end" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.translate("on hand", { count: _ctx.getProductStock(item.productId).quantityOnHandTotal ?? '0' })), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_button, {
                                          fill: "clear",
                                          onClick: _withModifiers(($event: any) => (_ctx.openInventoryDetailPopover($event, item)), ["stop"])
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              slot: "icon-only",
                                              icon: _ctx.informationCircleOutline,
                                              color: "medium"
                                            }, null, 8, ["icon"])
                                          ]),
                                          _: 2
                                        }, 1032, ["onClick"])
                                      ]))
                                    : (_openBlock(), _createBlock(_component_ion_button, {
                                        key: 4,
                                        fill: "clear",
                                        onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId, _ctx.order.shipGroupSeqId)), ["stop"])
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, {
                                            color: "medium",
                                            slot: "icon-only",
                                            icon: _ctx.cubeOutline
                                          }, null, 8, ["icon"])
                                        ]),
                                        _: 2
                                      }, 1032, ["onClick"])),
                                (_ctx.isKit(item))
                                  ? (_openBlock(), _createBlock(_component_ion_button, {
                                      key: 5,
                                      fill: "clear",
                                      size: "small",
                                      onClick: _withModifiers(($event: any) => (_ctx.fetchKitComponents(item, _ctx.order)), ["stop"])
                                    }, {
                                      default: _withCtx(() => [
                                        (item.showKitComponents)
                                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 0,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.chevronUpOutline
                                            }, null, 8, ["icon"]))
                                          : (_openBlock(), _createBlock(_component_ion_icon, {
                                              key: 1,
                                              color: "medium",
                                              slot: "icon-only",
                                              icon: _ctx.listOutline
                                            }, null, 8, ["icon"]))
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]))
                                  : _createCommentVNode("", true)
                              ])
                            ]),
                            _: 2
                          }, 1024),
                          (_ctx.isKit(item) && item.showKitComponents)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                (!_ctx.getProduct(item.productId)?.productComponents)
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createVNode(_component_ion_item, { lines: "none" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: "",
                                            style: {"height":"80%"}
                                          })
                                        ]),
                                        _: 1
                                      }),
                                      _createVNode(_component_ion_item, { lines: "none" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_skeleton_text, {
                                            animated: "",
                                            style: {"height":"80%"}
                                          })
                                        ]),
                                        _: 1
                                      })
                                    ], 64))
                                  : (_ctx.getProduct(item.productId)?.productComponents)
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.getProduct(item.productId).productComponents, (productComponent, index) => {
                                        return (_openBlock(), _createBlock(_component_ion_item, {
                                          lines: "none",
                                          key: index
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_DxpShopifyImg, {
                                                  src: _ctx.getProduct(productComponent.productIdTo).mainImageUrl,
                                                  size: "small"
                                                }, null, 8, ["src"])
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(productComponent.productIdTo))), 1),
                                                _createTextVNode(" " + _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(productComponent.productIdTo)) : productComponent.productIdTo), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            (item.rejectReason || _ctx.isEntierOrderRejectionEnabled(_ctx.order))
                                              ? (_openBlock(), _createBlock(_component_ion_checkbox, {
                                                  key: 0,
                                                  slot: "end",
                                                  "aria-label": "Rejection Reason kit component",
                                                  checked: item.rejectedComponents?.includes(productComponent.productIdTo),
                                                  onIonChange: ($event: any) => (_ctx.rejectKitComponent(_ctx.order, item, productComponent.productIdTo)),
                                                  color: "danger"
                                                }, null, 8, ["checked", "onIonChange"]))
                                              : _createCommentVNode("", true)
                                          ]),
                                          _: 2
                                        }, 1024))
                                      }), 128))
                                    : _createCommentVNode("", true)
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  (!_ctx.order.part?.items?.length && _ctx.orderType === 'open')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.translate("All order items are rejected")), 1))
                    : _createCommentVNode("", true),
                  (!_ctx.order.part?.items?.length && _ctx.orderType === 'packed')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.translate("All order items are cancelled")), 1))
                    : _createCommentVNode("", true),
                  (_ctx.orderType === 'packed')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                        (_ctx.isCancelationSyncJobEnabled && _ctx.isProcessRefundEnabled)
                          ? (_openBlock(), _createBlock(_component_ion_item, {
                              key: 0,
                              lines: "none"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.checkmarkDoneOutline
                                }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.translate("Cancelation and refund sync to Shopify is enabled.")), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : (_ctx.isCancelationSyncJobEnabled)
                            ? (_openBlock(), _createBlock(_component_ion_item, {
                                key: 1,
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: _ctx.warningOutline
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Cancelation sync to Shopify is enabled. Refund processing is disabled.")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_item, {
                                key: 2,
                                lines: "none"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_icon, {
                                    slot: "start",
                                    icon: _ctx.closeOutline
                                  }, null, 8, ["icon"]),
                                  _createVNode(_component_ion_label, null, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate("Cancelation and refund sync to Shopify is not enabled.")), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                      ], 64))
                    : _createCommentVNode("", true),
                  (_ctx.orderType === 'open' && _ctx.order.part?.items?.length)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 5,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_button, {
                            size: "default",
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected || _ctx.hasRejectedItems,
                            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.readyForPickup(_ctx.order, _ctx.order.part)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                slot: "start",
                                icon: _ctx.bagCheckOutline
                              }, null, 8, ["icon"]),
                              _createTextVNode(" " + _toDisplayString(_ctx.order?.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.translate("Ready for pickup") : _ctx.translate("Ready to ship")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"]),
                          _createVNode(_component_ion_button, {
                            size: "default",
                            disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected || !_ctx.hasRejectedItems,
                            color: "danger",
                            fill: "outline",
                            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.rejectOrder()))
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Reject Items")), 1)
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ]),
                        _: 1
                      }))
                    : (_ctx.orderType === 'packed' && _ctx.order.part?.items?.length)
                      ? (_openBlock(), _createBlock(_component_ion_item, {
                          key: 6,
                          lines: "none",
                          class: "ion-hide-md-down"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_button, {
                              size: "default",
                              disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped || _ctx.order.cancelled || _ctx.hasCancelledItems,
                              expand: "block",
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.deliverShipment(_ctx.order)))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  icon: _ctx.checkmarkDoneOutline
                                }, null, 8, ["icon"]),
                                _createTextVNode(" " + _toDisplayString(_ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.translate("Handover") : _ctx.translate("Ship")), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"]),
                            _createVNode(_component_ion_button, {
                              color: "danger",
                              size: "default",
                              disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped || _ctx.order.cancelled || !_ctx.hasCancelledItems,
                              expand: "block",
                              fill: "outline",
                              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.cancelOrder(_ctx.order)))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Cancel items")), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_13, [
                    _createVNode(_component_ion_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.order?.customer?.name || _ctx.order?.customer?.partyId), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.mailOutline,
                                  slot: "start"
                                }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.order.billingEmail || "-"), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.callOutline,
                                  slot: "start"
                                }, null, 8, ["icon"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.order.billingPhone || "-"), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_ion_item, { lines: "none" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  icon: _ctx.cashOutline,
                                  slot: "start"
                                }, null, 8, ["icon"]),
                                (_ctx.order.billingAddress)
                                  ? (_openBlock(), _createBlock(_component_ion_label, {
                                      key: 0,
                                      class: "ion-text-wrap"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.order.billingAddress.toName) + " ", 1),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.order.billingAddress.address1), 1),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.order.billingAddress.address2), 1),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.order.billingAddress.city) + " " + _toDisplayString(_ctx.order.billingAddress.city && _ctx.order.billingAddress.postalCode && ',') + " " + _toDisplayString(_ctx.order.billingAddress.postalCode), 1),
                                        _createElementVNode("p", null, _toDisplayString(_ctx.order.billingAddress.stateName) + " " + _toDisplayString(_ctx.order.billingAddress.stateName && _ctx.order.billingAddress.countryName && ',') + " " + _toDisplayString(_ctx.order.billingAddress.countryName), 1)
                                      ]),
                                      _: 1
                                    }))
                                  : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString("-"))
                                      ]),
                                      _: 1
                                    }))
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_card, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_card_header, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_card_title, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.translate("Payment")), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        (_ctx.order.orderPayments?.length)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.orderPayments, (orderPayment, index) => {
                                return (_openBlock(), _createBlock(_component_ion_list, { key: index }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_item, { lines: "none" }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_15, _toDisplayString(orderPayment.methodTypeId), 1),
                                            _createVNode(_component_ion_label, null, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.getPaymentMethodDesc(orderPayment.methodTypeId)) || orderPayment.methodTypeId), 1)
                                              ]),
                                              _: 2
                                            }, 1024),
                                            _createVNode(_component_ion_note, {
                                              color: _ctx.getColorByDesc(_ctx.getStatusDesc(orderPayment.paymentStatus))
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.translate(_ctx.getStatusDesc(orderPayment.paymentStatus))), 1)
                                              ]),
                                              _: 2
                                            }, 1032, ["color"])
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createElementVNode("div", _hoisted_16, [
                                          (_ctx.order.orderPayments.length > 1 && index === 0)
                                            ? (_openBlock(), _createBlock(_component_ion_badge, {
                                                key: 0,
                                                color: "dark"
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.translate("Latest")), 1)
                                                ]),
                                                _: 1
                                              }))
                                            : _createCommentVNode("", true),
                                          _createVNode(_component_ion_label, { slot: "end" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(_ctx.formatCurrency(orderPayment.amount, _ctx.order.currencyUom)), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.translate("No payments found")), 1))
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  (_ctx.order.shipGroups?.filter((group) => group.shipGroupSeqId !== _ctx.order.part.orderPartSeqId)?.length)
                    ? (_openBlock(), _createBlock(_component_ion_item, {
                        key: 0,
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Other shipments in this order")), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_19, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.order.shipGroups, (shipGroup) => {
                      return (_openBlock(), _createElementBlock(_Fragment, {
                        key: shipGroup.shipmentId
                      }, [
                        (shipGroup.shipGroupSeqId !== _ctx.order.part.orderPartSeqId)
                          ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_card_header, null, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_ion_card_subtitle, { class: "overline" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.getfacilityTypeDesc(shipGroup.facilityTypeId)), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_card_title, null, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(shipGroup.facilityName), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createTextVNode(" " + _toDisplayString(shipGroup.shipGroupSeqId), 1)
                                    ]),
                                    _createVNode(_component_ion_badge, {
                                      color: shipGroup.category ? 'primary' : 'medium'
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(shipGroup.category ? shipGroup.category : _ctx.translate('Pending allocation')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["color"])
                                  ]),
                                  _: 2
                                }, 1024),
                                (shipGroup.carrierPartyId)
                                  ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.getPartyName(shipGroup.carrierPartyId)) + " ", 1),
                                        _createVNode(_component_ion_label, { slot: "end" }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(shipGroup.trackingCode), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_icon, {
                                          slot: "end",
                                          icon: _ctx.locateOutline
                                        }, null, 8, ["icon"])
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (shipGroup.shippingInstructions)
                                  ? (_openBlock(), _createBlock(_component_ion_item, {
                                      key: 1,
                                      color: "light",
                                      lines: "none"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_20, _toDisplayString(_ctx.translate("Handling Instructions")), 1),
                                            _createElementVNode("p", null, _toDisplayString(shipGroup.shippingInstructions), 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(shipGroup?.items, (item) => {
                                  return (_openBlock(), _createBlock(_component_ion_item, {
                                    lines: "none",
                                    key: item
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_DxpShopifyImg, {
                                            src: _ctx.getProduct(item.productId).mainImageUrl,
                                            size: "small"
                                          }, null, 8, ["src"])
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                                          _createElementVNode("p", _hoisted_21, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createElementVNode("div", _hoisted_22, [
                                        (item.isFetchingStock)
                                          ? (_openBlock(), _createBlock(_component_ion_spinner, {
                                              key: 0,
                                              color: "medium",
                                              name: "crescent"
                                            }))
                                          : (_ctx.getProductStock(item.productId).quantityOnHandTotal >= 0)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                                _createVNode(_component_ion_note, { slot: "end" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.translate("on hand", { count: _ctx.getProductStock(item.productId).quantityOnHandTotal ?? '0' })), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024),
                                                _createVNode(_component_ion_button, {
                                                  fill: "clear",
                                                  onClick: _withModifiers(($event: any) => (_ctx.openInventoryDetailPopover($event, item)), ["stop"])
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, {
                                                      slot: "icon-only",
                                                      icon: _ctx.informationCircleOutline,
                                                      color: "medium"
                                                    }, null, 8, ["icon"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]))
                                            : (_openBlock(), _createBlock(_component_ion_button, {
                                                key: 2,
                                                fill: "clear",
                                                onClick: _withModifiers(($event: any) => (_ctx.fetchProductStock(item.productId, shipGroup.shipGroupSeqId)), ["stop"])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_ion_icon, {
                                                    color: "medium",
                                                    slot: "icon-only",
                                                    icon: _ctx.cubeOutline
                                                  }, null, 8, ["icon"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onClick"]))
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true)
                      ], 64))
                    }), 128))
                  ])
                ])
              ])),
          (_ctx.orderType === 'open' && _ctx.order?.orderId)
            ? (_openBlock(), _createBlock(_component_ion_fab, {
                key: 2,
                class: "ion-hide-md-up",
                vertical: "bottom",
                horizontal: "end",
                slot: "fixed"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_fab_button, {
                    disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.readyToHandover || _ctx.order.readyToShip || _ctx.order.rejected,
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.readyForPickup(_ctx.order, _ctx.order.part)))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.order.part?.shipmentMethodEnum?.shipmentMethodEnumId === 'STOREPICKUP' ? _ctx.bagHandleOutline : _ctx.giftOutline
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              }))
            : (_ctx.orderType === 'packed' && _ctx.order?.orderId)
              ? (_openBlock(), _createBlock(_component_ion_fab, {
                  key: 3,
                  class: "ion-hide-md-up",
                  vertical: "bottom",
                  horizontal: "end",
                  slot: "fixed"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_fab_button, {
                      disabled: !_ctx.hasPermission(_ctx.Actions.APP_ORDER_UPDATE) || _ctx.order.handovered || _ctx.order.shipped || _ctx.order.cancelled,
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.deliverShipment(_ctx.order)))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDoneOutline }, null, 8, ["icon"])
                      ]),
                      _: 1
                    }, 8, ["disabled"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}